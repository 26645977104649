import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import {nl} from 'date-fns/esm/locale'
import { Input, Icon } from 'semantic-ui-react';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('nl', nl);

var current_datetime;


function calOpen(){
  current_datetime = $('#planning_item_start_time').val();
}

function calClose(){
  console.log("Calendar closed");
  var prev = moment(current_datetime, "DD-MM-YYYY HH:mm");
  var cur = moment($('#planning_item_start_time').val(), "DD-MM-YYYY HH:mm");
  var diff = cur - prev;
  if ($('#planning_item_end_time').val() != ''){
    var new_endtime = moment($('#planning_item_end_time').val(), "DD-MM-YYYY HH:mm").add(diff, 'ms');
    $('#planning_item_end_time').val(new_endtime.format("DD-MM-YYYY HH:mm"));
  } else {
    var ini_endtime = moment($('#planning_item_start_time').val(), "DD-MM-YYYY HH:mm").add(1, 'h');
    $('#planning_item_end_time').val(ini_endtime.format("DD-MM-YYYY HH:mm"));
  }
}


const DateTimePickerCal = (prms) => {
  console.log('DateTimePickerCal');
  console.log(prms.date);


  const [startDate, setStartDate] = useState(new Date(prms.date));

  const handleCalendarClose = () => calClose();
  const handleCalendarOpen = () => calOpen();

  const CustomInput = forwardRef(({ value, onClick }, _ref) => (
    <div className="input-group date">
      <input type="text" className="form-control" defaultValue={value} name={prms.name} id={prms.id} onClick={onClick}/>
      <div className="input-group-append">
        <div className="input-group-text" onClick={onClick} ref={_ref} style={{cursor: 'pointer'}}><i className="fas fa-calendar-alt"></i></div>
      </div>
    </div>
  ));

  const ref = React.createRef();

  if (prms.time == true){
    return (
      <DatePicker
        className = "form-control"
        selected={startDate}
        onChange={date => setStartDate(date)}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        showTimeSelect
        timeFormat={prms.tfrmt}
        timeIntervals={prms.int}
        timeCaption={prms.tcap}
        showWeekNumbers
        locale='nl'
        dateFormat={prms.dfrmt}
        customInput={<CustomInput ref={ref} />}
      />
    );
  } else {
    return (
      <DatePicker
        className = "form-control"
        selected={startDate}
        onChange={date => setStartDate(date)}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        showWeekNumbers
        locale='nl'
        dateFormat={prms.dfrmt}
        customInput={<CustomInput ref={ref} />}
      />
    );
  }
};

export default DateTimePickerCal
