// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("popper.js");
require("bootstrap");
require("datatables.net-bs4");
require("moment");
require("@ckeditor/ckeditor5-build-classic");
//require("tempusdominus-bootstrap-4");

import "@fortawesome/fontawesome-free/js/all";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import $ from 'jquery';
global.$ = jQuery;

require('jquery-ui');

import "jquery-ui-dist/jquery-ui.css"
import "../stylesheets/application.scss";
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css"
require("bootstrap-switch");
