import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes from "prop-types"

import 'react-bootstrap-typeahead/css/Typeahead.css';

const ref = React.createRef();

const filterByClient = (option, props) => (
  option.label.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
);


class TypeaheadContactsInClient extends React.Component {

  processVal(sel, target){
    if (typeof sel.selected[0] != 'undefined'){
      console.log(target)
      $(target).val(sel.selected[0].id)
      this.setState(sel);
      console.log(sel.selected[0].label);
      console.log(sel.selected[0].label.substr(0,1));
      changeOptions(sel.selected[0].label.substr(0,1));
    } else {
      console.log('geen geldige waarde');
    }
  }

  state = {
    selected: [],
  };

  render() {
    return (
      <Typeahead
        {...this.state}
        filterBy={filterByClient}
        ref="typeahead"
        id={this.props.id}
        onChange={selected => this.processVal({ selected}, this.props.target)}
        options={this.props.options}
        placeholder={this.props.placeholder}
      />
    );
  }
}

TypeaheadContactsInClient.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  target: PropTypes.string
};

export default TypeaheadContactsInClient
