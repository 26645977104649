import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from "prop-types"

import 'react-datepicker/dist/react-datepicker.css';

const CustomInput = ({ value, onClick}) => (
  <div className="input-group date">
    <input type="text" className="form-control" defaultValue={value} onClick={onClick} name='adriaan' id='bassie'/>
    <div className="input-group-append" onClick={onClick}>
      <div className="input-group-text"><i className="fas fa-calendar-alt"></i></div>
    </div>
  </div>
);


class DateTimePicker extends React.Component {

  static get startDate(){
    return React.useState(new Date());
  }

  static set startDate(date){

  }

  render() {
    return (
      <DatePicker
        className = "form-control"
        selected={this.startDate}
        onChange={date => setStartDate(date)}
        showWeekNumbers
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        dateFormat="dd-MM-yyyy HH:mm"
        customInput={<CustomInput
          label="1"
          name="da"
                    />}
      />
    );
  }
}

DateTimePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string
};

export default DateTimePicker
