import React from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import {nl} from 'date-fns/esm/locale'

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('nl', nl);


const DateTimePickerEnd = () => {
  const [startDate, setStartDate] = React.useState(new Date());

  const CustomInput = ({ value, onClick, name, id}) => (
    <div className="input-group date">
      <input type="text" className="form-control" defaultValue={value} onClick={onClick} name="planning_item[end_time]" id="planning_item_end_time"/>
      <div className="input-group-append" onClick={onClick}>
        <div className="input-group-text"><i className="fas fa-calendar-alt"></i></div>
      </div>
    </div>
  );

  return (
    <DatePicker
      className = "form-control"
      selected={startDate}
      onChange={date => setStartDate(date)}
      showWeekNumbers
      locale='nl'
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="dd-MM-yyyy HH:mm"
      customInput={<CustomInput
        id="1"
        name="da"
                  />}
    />
  );
};

export default DateTimePickerEnd
